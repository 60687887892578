// extracted by mini-css-extract-plugin
export var post_entry = "singlePost-module--post_entry--2Le2D";
export var thumbnail = "singlePost-module--thumbnail--3Sjd9";
export var content = "singlePost-module--content--Ogxe5";
export var category = "singlePost-module--category--2EgPM";
export var date = "singlePost-module--date--2beGZ";
export var post_heading = "singlePost-module--post_heading--1kBKo";
export var post_text = "singlePost-module--post_text--1aMhS";
export var author_pic = "singlePost-module--author_pic--LFo3U";
export var author_container = "singlePost-module--author_container--3TBrz";
export var author_text = "singlePost-module--author_text--kTncU";